import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Input from '../basic/Input';
import Text from '../basic/text';
import LanguageSelector from '../basic/LanguageSelector';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import Switch from '../basic/Switch';
import Button from '../basic/Button';
import { toast } from 'react-toastify';
import Axios from 'axios';
import Loading from '../basic/Loading';
import { LanguagesContext } from '../../context/LanguagesContext';
import useLanguages from '../../hooks/useLanguages';
const Edit = (props) => {
    const hookslanguages = useLanguages();
    const {
        getLanguages,
        loading,
        setLoading,
        languages,
        setLanguages,
        edit,
        setEdit,
        selectList,
        setSelectList,
        edittype,
        setEdittype,
    } = useContext(LanguagesContext);

    const [data, setData] = useState({ isdefault: false });

    const setswitch = (value) => {
        setData({ ...data, isdefault: value });
    };

    const setlang = (code) => {
        setData({ ...data, language_id: code });
    };

    useEffect(() => {
        if (selectList[0]) {
            setLoading(true);
            Axios.get(`https://mb-6ifbzgvb.ew.gateway.dev/company/languages/${selectList[0]}`, {
                crossdomain: true,
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                },
            })
                .then((res) => {
                    setData({
                        isdefault: res.data[0].company_language_default_language,
                        language_id: res.data[0].company_language_language_id,
                        id: res.data[0].company_language_id,
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    error.response.status === 400 && toast.warning(error.response.data);
                    error.response.status === 500 && toast.error(error.response.data);
                });
        }
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <Wrapper>
            <WrapperTools>
                <Text blue1 fsize='24' fweight='900'>
                    {edittype === 'NEW' && 'New language'}
                    {edittype === 'EDIT' && 'Edit language'}
                </Text>
                <CloseBTN onClick={() => setEdit(false)} />
            </WrapperTools>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Default
                </Text>
                <Switch setswitch={setswitch} active={data.isdefault} />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Language
                </Text>
                <LanguageSelector all setlang={setlang} selected={data && data.language_id} />
            </Datarow>
            <Button
                color='primary'
                psr
                onClick={async () => {
                    try {
                        setLoading(true);

                        if (edittype === 'NEW') {
                            const res = await Axios.post(`https://mb-6ifbzgvb.ew.gateway.dev/company/languages`, data, {
                                crossdomain: true,
                                credentials: 'include',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                },
                            });
                            if (res) {
                                setLoading(false);
                                hookslanguages.getLanguages();
                                setEdit(false);
                            }
                        }

                        if (edittype === 'EDIT') {
                            const res = await Axios.put(
                                `https://mb-6ifbzgvb.ew.gateway.dev/company/languages/${selectList[0]}`,
                                data,
                                {
                                    crossdomain: true,
                                    credentials: 'include',
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                    },
                                },
                            );
                            if (res) {
                                setLoading(false);
                                hookslanguages.getLanguages();
                                setEdit(false);
                            }
                        }
                    } catch (error) {
                        error.response.status === 400 && toast.warning(error.response.data);
                        error.response.status === 500 && toast.error(error.response.data);
                    } finally {
                        setSelectList([]);
                    }
                }}
            >
                Save
            </Button>
        </Wrapper>
    );
};

export default Edit;
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    place-items: center flex-start;
`;
const WrapperTools = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    place-items: center flex-start;
    margin-bottom: 30px;
`;
const CloseBTN = styled(CloseIcon)`
    cursor: pointer;
    place-self: center flex-end;
    width: 36px;
    height: 36px;
`;
const Datarow = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(300px, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 20px;
    grid-template-rows: 1fr;
    place-items: center flex-start;
`;
