import react, { useState } from 'react'
import styled from 'styled-components'
import { CheckBox, CheckBoxLabel } from '../basic/SwitchInput'
import Button from '../basic/Button'
import MoreHorizIconFilled from '@material-ui/icons/MoreHoriz'
import MoreHorizIconOutlined from '@material-ui/icons/MoreVert'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

const DivItem = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 5px;

    border-bottom: ${(props) => `1px solid ${props.theme.colors.color2}`};
`
const DivSection = styled.div`
    position: relative;
    z-index: 1;
`
const DivSectionNoZIndex = styled.div`
    position: relative;
`

const Submenu = styled.div`
    position: absolute;
    top: 20px;

    transform: translateX(-45%);

    padding: 1rem;
    overflow: hidden;

    border: ${(props) => `1px solid ${props.theme.colors.color3}`};
    border-radius: 8px;
    background-color: ${(props) => `${props.theme.colors.color5}`};

    list-style: none;
    z-index: 1000;
`
const DeletePopUp = styled.div`
    position: relative;
    border: ${(props) => `1px solid ${props.theme.colors.color3}`};
    height: 300px;
    width: 600px;
    margin: auto;
    border-radius: 6px;
    background-color: white;
    top: 30%;
    transform: translateY(-50%);
    z-index: 3000;
    text-align: center;
`
const DeleteOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0;
    left: 0;
    background-color: rgba(12, 12, 11, 0.5);
    z-index: 200;
`

const UserslistItem = ({ data }) => {
    const [showSubmenu, setShowSubmenu] = useState(false)


return (
    <>
        <DivItem>

            <DivSection>{"Artemii Korshunov"}</DivSection>
            <DivSection>{"artemii@monke.com"}</DivSection>
            <DivSection>{"+35844235658"}</DivSection>
            <DivSection></DivSection>
            <DivSectionNoZIndex
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowSubmenu(!showSubmenu)}
                    onMouseLeave={() => setShowSubmenu(false)}
                >
                    {showSubmenu ? <MoreHorizIconFilled /> : <MoreHorizIconOutlined />}
                    {showSubmenu && (
                        <Submenu>
                            <ul style={{ listStyle: 'none', margin: '0 auto' }}>
                                <Link>
                                    Edit
                                </Link>
                                {/* <li onClick={DeleteItem}>Delete</li> */}
                            </ul>
                        </Submenu>
                    )}
                </DivSectionNoZIndex>



        </DivItem>

    </>
)
}
export default UserslistItem
