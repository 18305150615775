import React, { useEffect, useRef, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import Main from './pages/main';
import Products from './pages/products';
import Pages from './pages/pages';
import Login from './pages/login';
import UsersList from './pages/userslist';
import Languages from './pages/languages';
import { Privateroute } from './components/privateroute';
import 'react-toastify/dist/ReactToastify.css';
import Reports from './pages/reports';

const App = () => {
    return (
        <Switch>
            <Privateroute exact path='/' component={withRouter(Main)} />
            <Route path='/login' component={withRouter(Login)} />
            <Privateroute path='/settings' component={withRouter(Main)} />
            <Privateroute path='/languages' component={withRouter(Languages)} />
            <Privateroute path='/products' component={withRouter(Products)} />
            <Privateroute path='/pages' component={withRouter(Pages)} />
            <Privateroute path='/userslist' component={withRouter(UsersList)} />
            <Privateroute path='/reports' component={withRouter(Reports)} />
        </Switch>
    );
};

export default App;
