import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size:100%;
    font-family: "Lato", Sans-Serif;
    overflow:hidden;
  }
  a{
    text-decoration:none;
    color:black;
  }
`;

export default GlobalStyle;
