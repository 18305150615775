import React from 'react';
import styled from 'styled-components';
const ColumnRow = (props) => {
    return <ColumnWrapper {...props}>{props.children}</ColumnWrapper>;
};

export default ColumnRow;

const ColumnWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.blue1};
    color: #fff;
    display: grid;
    height: 50px;
    grid-template-columns: ${(props) => (props.columns ? props.columns : '100px')};
    place-items: center flex-start;
    grid-column-gap: 20px;
    grid-template-rows: 1fr;
    border-bottom: ${(props) => `3px solid ${props.theme.colors.color2}`};
`;
