import UserslistComponent from '../components/UsersList/index'

const Userslist= () => {
    return (
        <>
    <UserslistComponent />
     </>)

}
export default Userslist
