import React from 'react';
import styled from 'styled-components';
const Checkbox = (props) => {
    return <CheckBoxItem {...props} />;
};

export default Checkbox;
const CheckBoxItem = styled.input`
    place-self: center;
    margin: 0;
    width: 18px;
    height: 18px;
`;
