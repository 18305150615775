import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Button from '../components/basic/Button';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Text from '../components/basic/text';
import Loading from '../components/basic/Loading';
import Table from '../components/Table/Table';
import ColumnRow from '../components/Table/ColumnRow';
import RowItem from '../components/Languages/RowItem';
import OverlayEdit from '../components/basic/OverlayEdit';
import Edit from '../components/Languages/Edit';
import useLanguages from '../hooks/useLanguages';
import { LanguagesContext } from '../context/LanguagesContext';
const Languages = () => {
    const hooklanguages = useLanguages();
    const { languages, setLanguages, edit, setEdit, selectList, setSelectList, edittype, setEdittype } = useContext(
        LanguagesContext,
    );

    useEffect(() => {
        setSelectList([]);
        hooklanguages.getLanguages();
    }, []);
    return languages.length > 0 ? (
        <Wrapper>
            {edit && (
                <OverlayEdit animplay={edit}>
                    <Edit />
                </OverlayEdit>
            )}
            <ButtonsWrapper>
                {selectList.length > 1 && (
                    <Button
                        color='secondary'
                        onClick={async () => {
                            Axios.post('https://mb-6ifbzgvb.ew.gateway.dev/company/languages/bulk-delete', selectList, {
                                crossdomain: true,
                                credentials: 'include',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                },
                            })
                                .then((res) => {
                                    setSelectList([]);
                                    hooklanguages.getLanguages();
                                })
                                .catch((error) => {
                                    error.response.status === 400 && toast.warning(error.response.data);
                                    error.response.status === 500 && toast.error(error.response.data);
                                });
                        }}
                    >{`Delete (${selectList.length})`}</Button>
                )}
                {selectList.length === 1 && (
                    <Button
                        color='edit'
                        onClick={() => {
                            if (selectList.length > 1) {
                                toast.error('Please select only 1 language');
                            } else {
                                if (selectList.length < 1) {
                                    toast.error('Please select 1 language');
                                } else {
                                    setEdittype('EDIT');
                                    setEdit(true);
                                }
                            }
                        }}
                    >
                        {`Edit (${selectList.length})`}
                    </Button>
                )}
                <Button
                    color='primary'
                    onClick={() => {
                        setEdittype('NEW');
                        setEdit(true);
                    }}
                >
                    New
                </Button>
            </ButtonsWrapper>
            <Table>
                <ColumnRow columns='1fr 1fr 6fr 2fr'>
                    <div></div>
                    <Text fsize='14' fweight='700'>
                        Flag
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Language
                    </Text>

                    <Text fsize='14' fweight='700'>
                        Default
                    </Text>
                </ColumnRow>

                {languages.map((item, i) => (
                    <RowItem data={item} key={i} />
                ))}
            </Table>
        </Wrapper>
    ) : (
        <Loading />
    );
};

export default Languages;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
`;

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-column-gap: 10px;
    place-content: center flex-end;
`;
