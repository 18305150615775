import react, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import UserslistItem from './Userslistitem';
import Button from '../basic/Button';
import { Link } from 'react-router-dom';

const UserslistWrapper = styled.div`
    display: grid;
    border: ${(props) => `3px solid ${props.theme.colors.color3}`};
    margin: 10px;
`;
const ColumnWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-bottom: ${(props) => `3px solid ${props.theme.colors.color2}`};
`;

const ColumnObject = styled.div``;

const Userslist = () => {
    return (
        <>
            <UserslistWrapper>
                <ColumnWrapper>
                    <ColumnObject>Name</ColumnObject>
                    <ColumnObject>Email</ColumnObject>
                    <ColumnObject>Phone</ColumnObject>
                    <ColumnObject>Adress</ColumnObject>
                </ColumnWrapper>
                <UserslistItem />
                <UserslistItem />
                <UserslistItem />
                <UserslistItem />
            </UserslistWrapper>
        </>
    );
};
export default Userslist;
