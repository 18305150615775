import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const Loading = () => {
    return (
        <Wrapper>
            <Logo src='/mb_logo.png' />
        </Wrapper>
    );
};

export default Loading;

const open = keyframes`
  0% {
    width: 20vw;


  }
  50% {
    width: 0vw;
  }


  100% {
    width: 20vw;
}
`;

const Wrapper = styled.div`
    /* background-color: ${(props) => props.theme.colors.blue1}; */

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100vh;
    place-items: center;
`;
const Logo = styled.img`
    width: 20vw;
    justify-self: center;
    margin: 30px 0px;

    animation: ${open} linear 1.5s infinite;
`;
