import styled from 'styled-components';

const Input = styled.input`
    font-size: 14px;
    font-family: 'Lato', Sans-Serif;
    padding: 15px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
    outline: 0px;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    :focus {
        border: ${(props) => `1px solid ${props.theme.colors.blue1}`};
    }
`;

export default Input;
