import React from 'react';
import styled from 'styled-components';
const CountryFlag = (props) => {
    return <CountryFlagItem {...props} />;
};

export default CountryFlag;
const CountryFlagItem = styled.img`
    cursor: pointer;
    height: 16px;
    :hover {
        box-shadow: ${(props) => `0px 0px 0px 2px ${props.theme.colors.yellow}`};
    }
    :focus {
        box-shadow: ${(props) => `0px 0px 0px 2px ${props.theme.colors.yellow}`};
    }
    ${(props) => props.disabled && `opacity: 0.2;`}
`;
