import React, { useState } from 'react';
import styled from 'styled-components';
const NavItem = (props) => {
    return <LinkItem active={props.selected}>{props.children}</LinkItem>;
};

export default NavItem;

const LinkItem = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    place-items: center flex-start;
    grid-gap: 20px;
    color: #fff;
    padding: 10px 0px;
    padding-left: 20px;
    text-indent: 20px;
    font-size: 16px;
    border-left: ${(props) =>
        props.active ? `10px solid ${props.theme.colors.orange}` : `10px solid ${props.theme.colors.blue1}`};
    /* :hover {
        border-left: 10px solid ${(props) => `${props.theme.colors.orange}`};
    } */
`;
