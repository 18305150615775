import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/context';
import Axios from 'axios';
import styled, { keyframes, css } from 'styled-components';
import Button from '../components/basic/Button';
import { toast } from 'react-toastify';
import Rowitem from '../components/PageList/Rowitem';
import New from '../components/PageList/New';
import Text from '../components/basic/text';
import OverlayEdit from '../components/basic/OverlayEdit';
import { PagesContext } from '../context/PagesContext';

const PageList = () => {
    // const { ALLPAGES, SHOWDIVANIMATION, PAGEDATA, PAGEDELETELIST, LOADING } = useContext(AppContext);
    // const [allpages, setAllpages] = ALLPAGES;
    // const [showdivanimation, setShowdivanimation] = SHOWDIVANIMATION;
    // const [pagedata, setPagedata] = PAGEDATA;
    // const [pagedeletelist, setPagedeletelist] = PAGEDELETELIST;
    // const [showloading, setLoading] = LOADING;

    const {
        loading,
        setLoading,
        edit,
        setEdit,
        allpages,
        setAllpages,
        page,
        setPage,
        selectList,
        setSelectList,
    } = useContext(PagesContext);

    const getPages = async () => {
        setLoading(true);
        Axios.get('https://mb-6ifbzgvb.ew.gateway.dev/pages', {
            crossdomain: true,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
            },
        })
            .then((res) => {
                setAllpages(res.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                error.response.status === 400 && toast.warning(error.response.data);
                error.response.status === 500 && toast.error(error.response.data);
            });
    };

    useEffect(() => {
        getPages();
    }, []);

    return (
        <>
            {edit && (
                <OverlayEdit animplay={edit}>
                    <New getPages={getPages} />
                </OverlayEdit>
            )}

            <ButtonsWrapper>
                {selectList.length > 0 && (
                    <Button
                        color='secondary'
                        onClick={async () => {
                            try {
                                const res = await Axios.post(
                                    `https://mb-6ifbzgvb.ew.gateway.dev/pages/bulk-delete`,
                                    selectList,
                                    {
                                        crossdomain: true,
                                        credentials: 'include',
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                        },
                                    },
                                );
                                if (res) {
                                    if (res.status === 200) {
                                        toast.success(res.data);
                                        setSelectList([]);
                                        getPages();
                                    }
                                }
                            } catch (error) {
                                error.response.status === 400 && toast.warning(error.response.data);
                                error.response.status === 500 && toast.error(error.response.data);
                            }
                        }}
                    >{`Delete (${selectList.length})`}</Button>
                )}
                <Button
                    color='primary'
                    onClick={() => {
                        setEdit(true);
                        setPage({ type: 'New page' });
                    }}
                >
                    New page
                </Button>
            </ButtonsWrapper>

            <PagelistWrapper>
                <ColumnWrapper>
                    <div></div>
                    <Text fsize='14' fweight='700'>
                        Name
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Languages
                    </Text>
                </ColumnWrapper>
                {allpages.map((item, i) => (
                    <Rowitem data={item} key={i} />
                ))}
            </PagelistWrapper>
        </>
    );
};
export default PageList;

const PagelistWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;
`;
const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-column-gap: 10px;
    place-content: center flex-end;
`;
const ColumnWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.blue1};
    color: #fff;
    display: grid;
    height: 50px;
    grid-template-columns: 80px 1fr 2fr;
    place-items: center flex-start;
    grid-template-rows: 1fr;
    border-bottom: ${(props) => `3px solid ${props.theme.colors.color2}`};
`;
