import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import Input from '../basic/Input';
import Text from '../basic/text';
import Button from '../basic/Button';
import Switch from '../basic/Switch';
import React, { useState, useContext } from 'react';
import { PagesContext } from '../../context/PagesContext';
import LanguageSelector from '../basic/LanguageSelector';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { GooglePreview, GoogleMobilePreview } from 'serp-preview';
import { ReactComponent as GoogleDesktopIco } from '../../icons/google_desktop.svg';
import { ReactComponent as GoogleMobileIco } from '../../icons/google_mobile.svg';
import Textarea from '../basic/Textarea';
const New = (props) => {
    const {
        loading,
        setLoading,
        edit,
        setEdit,
        allpages,
        setAllpages,
        page,
        setPage,
        selectList,
        setSelectList,
    } = useContext(PagesContext);

    const setswitch = (value) => {
        setPage({ ...page, active: value });
    };

    const setlang = (code) => {
        setPage({ ...page, language_id: code });
    };

    return (
        <Wrapper>
            <WrapperTools>
                <Text blue1 fsize='24' fweight='700'>
                    {page.type}
                </Text>
                <CloseBTN
                    onClick={() => {
                        setEdit(false);
                        setPage({});
                    }}
                />
            </WrapperTools>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Active
                </Text>

                <Switch setswitch={setswitch} active={page ? page.active : false} />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Name
                </Text>
                <Text fsize='14' fweight='600'>
                    {page ? page.name : ''}
                </Text>
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Language
                </Text>
                <LanguageSelector company setlang={setlang} selected={page && page.language_id} />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Old url
                </Text>
                <Input
                    width={600}
                    defaultValue={page ? page.oldurl : ''}
                    onChange={(e) => setPage({ ...page, oldurl: e.target.value })}
                />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    New url
                </Text>
                <Input
                    width={600}
                    defaultValue={page ? page.url : ''}
                    onChange={(e) => setPage({ ...page, url: e.target.value })}
                />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Meta title
                </Text>
                <Input
                    width={600}
                    defaultValue={page ? page.meta_title : ''}
                    onChange={(e) => setPage({ ...page, meta_title: e.target.value, name: e.target.value })}
                />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Meta Desc
                </Text>
                <Textarea
                    style={{ resize: 'none' }}
                    maxlength='160'
                    width={600}
                    defaultValue={page ? page.meta_description : ''}
                    onChange={(e) => setPage({ ...page, meta_description: e.target.value })}
                />
            </Datarow>
            <Datarow>
                <Text fsize='14' fweight='400'>
                    Meta keywords
                </Text>
                <Input
                    width={600}
                    defaultValue={page ? page.meta_keywords : ''}
                    onChange={(e) => setPage({ ...page, meta_keywords: e.target.value })}
                />
            </Datarow>

            <GooglePreviewContainer>
                <GooglePreviewRow>
                    <GoogleDesktopIcon />
                    <GooglePreview
                        title={page && page.meta_title}
                        description={page && page.meta_description}
                        url={`https://merittabra.${page && page.language_code && page.language_code.toLowerCase()}/${
                            page && page.url
                        }`}
                        link={`https://merittabra.${page && page.language_code && page.language_code.toLowerCase()}/${
                            page && page.url
                        }`}
                    />
                </GooglePreviewRow>
                <GooglePreviewRow>
                    <GoogleMobileIcon />
                    <GoogleMobilePreview
                        title={page && page.meta_title}
                        description={page && page.meta_description}
                        url={`https://merittabra.${page && page.language_code && page.language_code.toLowerCase()}/${
                            page && page.url
                        }`}
                        link={`https://merittabra.${page && page.language_code && page.language_code.toLowerCase()}/${
                            page && page.url
                        }`}
                    />
                </GooglePreviewRow>
            </GooglePreviewContainer>

            <Editor
                apiKey='27e0f5bbt1br9ieinqp3mhsp24iq4t8u0w2ac28e7pdgjs2t'
                value={page ? page.content : ''}
                init={{
                    height: 700,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'image',
                        'code',
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | image |code|help',
                }}
                onEditorChange={(content) => setPage({ ...page, content: content })}
            />
            <Buttonrow>
                {page && page.type === 'Edit page' && (
                    <Button
                        color='secondary'
                        onClick={async () => {
                            setLoading(true);
                            if (page.type === 'Edit page') {
                                try {
                                    const res = await Axios.post(
                                        `https://mb-6ifbzgvb.ew.gateway.dev/pages/bulk-delete`,
                                        [page.id],
                                        {
                                            crossdomain: true,
                                            credentials: 'include',
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                            },
                                        },
                                    );
                                    if (res) {
                                        if (res.status === 200) {
                                            setSelectList([]);

                                            props.getPages();
                                            setLoading(false);
                                            setEdit(false);
                                            setPage({});
                                        }
                                    }
                                } catch (error) {
                                    setLoading(false);
                                    error.response.status === 400 && toast.warning(error.response.data);
                                    error.response.status === 500 && toast.error(error.response.data);
                                }
                            }
                        }}
                    >
                        Delete
                    </Button>
                )}
                <Button
                    color='primary'
                    onClick={async () => {
                        setLoading(true);
                        if (page.type === 'Edit page') {
                            try {
                                const res = await Axios.put(
                                    `https://mb-6ifbzgvb.ew.gateway.dev/pages/${page.id}`,
                                    {
                                        active: page.active,
                                        name: page.name,
                                        language_id: page.language_id,
                                        oldurl: page.oldurl,
                                        url: page.url,
                                        content: page.content,
                                        meta_id: page.meta_id,
                                        meta_title: page.meta_title,
                                        meta_description: page.meta_description,
                                        meta_keywords: page.meta_keywords,
                                    },
                                    {
                                        crossdomain: true,
                                        credentials: 'include',
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                        },
                                    },
                                );
                                if (res) {
                                    if (res.status === 200) {
                                        props.getPages();
                                        setLoading(false);
                                        setEdit(false);
                                        setPage({});
                                    }
                                }
                            } catch (error) {
                                setLoading(false);
                                error.response.status === 400 && toast.warning(error.response.data);
                                error.response.status === 500 && toast.error(error.response.data);
                            }
                        }
                        if (page.type === 'New page') {
                            try {
                                const res = await Axios.post(
                                    'https://mb-6ifbzgvb.ew.gateway.dev/pages',
                                    {
                                        active: page.active,
                                        name: page.name,
                                        language_id: page.language_id,
                                        oldurl: page.oldurl,
                                        url: page.url,
                                        content: page.content,
                                        meta_title: page.meta_title,
                                        meta_description: page.meta_description,
                                        meta_keywords: page.meta_keywords,
                                    },
                                    {
                                        crossdomain: true,
                                        credentials: 'include',
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                        },
                                    },
                                );
                                if (res) {
                                    if (res.status === 201) {
                                        props.getPages();
                                        setLoading(false);
                                        setEdit(false);
                                        setPage({});
                                    }
                                }
                            } catch (error) {
                                setLoading(false);
                                error.response.status === 400 && toast.warning(error.response.data);
                                error.response.status === 500 && toast.error(error.response.data);
                            }
                        }

                        if (page.type === 'Add language') {
                            try {
                                const res = await Axios.post(
                                    `https://mb-6ifbzgvb.ew.gateway.dev/pages/language/${page.identifier}`,
                                    {
                                        active: page.active,
                                        name: page.name,
                                        language_id: page.language_id,
                                        oldurl: page.oldurl,
                                        url: page.url,
                                        content: page.content,
                                        meta_title: page.meta_title,
                                        meta_description: page.meta_description,
                                        meta_keywords: page.meta_keywords,
                                    },
                                    {
                                        crossdomain: true,
                                        credentials: 'include',
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                                        },
                                    },
                                );
                                if (res) {
                                    if (res.status === 201) {
                                        props.getPages();
                                        setLoading(false);
                                        setEdit(false);
                                    }
                                }
                            } catch (error) {
                                setLoading(false);
                                error.response.status === 400 && toast.warning(error.response.data);
                                error.response.status === 500 && toast.error(error.response.data);
                            }
                        }
                    }}
                >
                    Save
                </Button>
            </Buttonrow>
        </Wrapper>
    );
};

export default New;

const Wrapper = styled.div`
    padding: 20px 0px;
    display: grid;
    grid-template-columns: 900px;
    grid-template-rows: 50px 1fr;
    grid-row-gap: 10px;
`;

const WrapperTools = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    place-items: center flex-start;
`;

const CloseBTN = styled(CloseIcon)`
    cursor: pointer;
    place-self: center flex-end;
    width: 36px;
    height: 36px;
`;

const Datarow = styled.div`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: minmax(50px, auto);
    place-items: center flex-start;
`;
const Buttonrow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-column-gap: 10px;
    place-content: center flex-end;
`;

const GoogleDesktopIcon = styled(GoogleDesktopIco)`
    width: 250px;
    height: auto;
`;
const GoogleMobileIcon = styled(GoogleMobileIco)`
    width: 250px;
    height: auto;
`;

const GooglePreviewRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px auto;
    grid-row-gap: 20px;
    place-items: flex-start flex-start;
`;
const GooglePreviewContainer = styled.div`
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
`;
