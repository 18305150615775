import React from 'react';
import styled from 'styled-components';
const ColorSwatch = (props) => {
    return <ColorSwatchItem {...props}>{props.children}</ColorSwatchItem>;
};

export default ColorSwatch;

const ColorSwatchItem = styled.div`
    background-color: ${(props) => props.color && props.color};
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
`;
