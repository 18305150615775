import firebase from 'firebase/app';
import 'firebase/auth';

export let fbapp = '';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyC8AXHzfveaD6j8qJOzfiED9NQ5X3FY0_s',
        authDomain: 'meritta-bra.firebaseapp.com',
    });
} else {
    firebase.app();
}

export const auth = firebase.auth();
export const persistence = firebase.auth.Auth.Persistence.SESSION;

export const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
provider.setCustomParameters({
    login_hint: 'user@example.com',
});

export const refreshtoken = () => {
    return new Promise((resolve, reject) => {
        auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                console.log(idToken);
                localStorage.setItem('MBS_TOKEN', idToken);
                resolve(idToken);
            })
            .catch(function (error) {
                reject();
            });
    });
};
