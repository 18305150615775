import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import NavItem from './NavItem';
import NavSubItem from './NavSubItem';
import { auth } from '../../services/firebase';

import { AppContext } from '../../context/context';

//SVG
import { ReactComponent as LanguagesIco } from '../../icons/nav_languages.svg';
import { ReactComponent as PagesIco } from '../../icons/nav_pages.svg';
import { ReactComponent as UsersIco } from '../../icons/nav_users.svg';
import { ReactComponent as ProductsIco } from '../../icons/nav_products.svg';
import { ReactComponent as ReportsIco } from '../../icons/nav_reports.svg';
import { ReactComponent as SettingsIco } from '../../icons/nav_settings.svg';
import { ReactComponent as LogoutIco } from '../../icons/nav_logout.svg';

const Nav = () => {
    const { CLEAN } = useContext(AppContext);
    const [selected, setSelected] = useState();
    const [selected2, setSelected2] = useState();
    const [menu, setMenu] = useState([
        { name: 'Languages', link: '/languages', icon: <LanguagesIcon /> },
        { name: 'Pages', link: '/pages', icon: <PagesIcon /> },
        { name: 'Users', link: '/userslist', icon: <UsersIcon /> },
        { name: 'Products', link: '/products', icon: <ProductsIcon /> },
        { name: 'Reports', link: '/reports', icon: <ReportsIcon /> },
        {
            name: 'Settings',
            link: '/settings',
            icon: <SettingsIcon />,
            submenu: [
                { name: 'Pages', link: '/pages' },
                { name: 'Users', link: '/userslist' },
                { name: 'Products', link: '/products' },
            ],
        },
    ]);
    return (
        <Wrapper>
            <Logo src='./mb_logo_white.svg' />
            {menu.map((x, i) => (
                <div key={i}>
                    <CustomLink
                        onClick={() => {
                            CLEAN();
                            setSelected(i);
                            setSelected2();
                        }}
                        to={x.link}
                    >
                        <NavItem selected={selected === i ? true : false}>
                            {x.icon}
                            {x.name}
                        </NavItem>
                    </CustomLink>

                    {selected === i &&
                        x.submenu &&
                        x.submenu.map((x, i) => (
                            <CustomLink
                                key={i}
                                onClick={() => {
                                    CLEAN();
                                    setSelected2(i);
                                }}
                                to={x.link}
                            >
                                <NavSubItem selected2={selected2 === i ? true : false}>{x.name}</NavSubItem>
                            </CustomLink>
                        ))}
                </div>
            ))}
            <CustomLink
                to='/login'
                onClick={() => {
                    auth.signOut()
                        .then(() => {
                            // Sign-out successful.
                        })
                        .catch((error) => {
                            // An error happened.
                        });
                }}
            >
                <NavItem type='LOGOUT'>
                    <LogoutIcon />
                    Logout
                </NavItem>
            </CustomLink>
        </Wrapper>
    );
};

export default Nav;
const Wrapper = styled.div`
    display: grid;
    background-color: ${(props) => `${props.theme.colors.blue1}`};
    z-index: 200;
    height: 100vh;
    position: fixed;
    top: 0;
    place-content: flex-start center;
    width: 240px;
`;

const CustomLink = styled(Link)`
    color: #fff;
`;

const Logo = styled.img`
    width: 70%;
    justify-self: center;
    margin: 30px 0px;
`;

const LanguagesIcon = styled(LanguagesIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const PagesIcon = styled(PagesIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const UsersIcon = styled(UsersIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const ProductsIcon = styled(ProductsIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const ReportsIcon = styled(ReportsIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const SettingsIcon = styled(SettingsIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;

const LogoutIcon = styled(LogoutIco)`
    width: 24px;
    height: 24px;
    path {
        fill: #fff;
    }
`;
