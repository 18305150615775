import React from 'react';
import styled from 'styled-components';
const Textarea = (props) => {
    return (
        <TEXTAREA {...props} rows='5' cols='60'>
            {props.children}
        </TEXTAREA>
    );
};

export default Textarea;

const TEXTAREA = styled.textarea`
    font-size: 14px;
    font-family: 'Lato', Sans-Serif;
    padding: 15px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
    outline: 0px;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    :focus {
        border: ${(props) => `1px solid ${props.theme.colors.blue1}`};
    }
`;
