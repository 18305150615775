import styled from 'styled-components';
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../context/context';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow_down2.svg';
import Text from '../basic/text';
import SwitchSmall from '../basic/SwitchSmall';
import { useHistory, Link } from 'react-router-dom';
import DataRow from '../Table/DataRow';
import Checkbox from '../basic/Checkbox';
import ColorSwatch from '../basic/ColorSwatch';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as WarehouseIco } from '../../icons/warehouse.svg';
import { ReactComponent as FactoryIco } from '../../icons/factory.svg';
import { ReactComponent as ConfigurableIco } from '../../icons/configurable.svg';
import { ReactComponent as SingleProductIco } from '../../icons/single_product.svg';
const Rowitem = (props) => {
    let history = useHistory();
    const { SHOWDIVANIMATION, PAGEDATA, PAGEDELETELIST } = useContext(AppContext);
    const [showdivanimation, setShowdivanimation] = SHOWDIVANIMATION;
    const [pagedata, setPagedata] = PAGEDATA;
    const [pagedeletelist, setPagedeletelist] = PAGEDELETELIST;
    const [checked, setChecked] = useState(false);

    return (
        <DataRow
            // delete={pagedeletelist.includes(props.data.id) && true}
            delete={checked}
            onClick={() => {
                // history.push('/products-edit', { productId: props.data.id });
            }}
        >
            <DataContainer>
                <Checkbox
                    type='checkbox'
                    defaultChecked={checked}
                    onClick={() => {
                        !checked ? setChecked(true) : setChecked(false);
                        // let dellanglist = [];
                        // props.data.languages.map((x) => dellanglist.push(x.id));
                        // if (!checked) {
                        //     setChecked(true);
                        //     setPagedeletelist([...pagedeletelist, ...dellanglist]);
                        // } else {
                        //     setChecked(false);
                        //     const filterlist = pagedeletelist.filter((x) => !dellanglist.includes(x) && x);
                        //     setPagedeletelist(filterlist);
                        // }
                    }}
                />
                {props.data.type === 'Simple' && <SingleProductIcoN />}
                {props.data.type === 'Configurable' && <ConfigurableIcon />}

                <Text fsize='13' fweight='400'>
                    {props.data.active}
                </Text>
                {!!props.data.image ? (
                    <div
                        style={{
                            background: `url(${props.data.image})`,
                            width: '40px',
                            height: '40px',
                            backgroundSize: 'cover',
                            justifySelf: 'center flex-start',
                            borderRadius: '30px',
                        }}
                    ></div>
                ) : (
                    <div></div>
                )}
                {/* <SwitchSmall active={props.data.active} /> */}

                {/* <Text fsize='13' fweight='400'>
                    {props.data.type}
                </Text> */}

                <Text fsize='13' fweight='400'>
                    {props.data.sku}
                </Text>
                <Text fsize='13' fweight='400'>
                    {props.data.name}
                </Text>
                {props.data.type !== 'Configurable' ? (
                    <PriceContainer>
                        {props.data.all_price ? (
                            props.data.all_price.map((x) => (
                                <Text fsize='13' fweight='700'>
                                    {new Intl.NumberFormat('fi-FI', {
                                        style: 'currency',
                                        currency: x.currency_code,
                                    }).format(x.product_price)}
                                </Text>
                            ))
                        ) : (
                            <Text fsize='13' fweight='400'>
                                No price
                            </Text>
                        )}
                    </PriceContainer>
                ) : (
                    <div></div>
                )}
                {props.data.type !== 'Configurable' ? (
                    <StockContainer>
                        {props.data.all_stock ? (
                            props.data.all_stock.map((x, i) =>
                                i === 0 ? <WarehouseIcon stock={false} /> : <FactoryIcon stock={true} />,
                            )
                        ) : (
                            <Text fsize='13' fweight='400'>
                                Not in stock
                            </Text>
                        )}
                    </StockContainer>
                ) : (
                    <div></div>
                )}
            </DataContainer>
            {props.data.type !== 'Configurable' && (
                <AttributesContainer>
                    {props.data.attributes &&
                        props.data.attributes.map((x, i) =>
                            x.product_attribute_value_swatch ? (
                                <ColorSwatch color={x.product_attribute_value_swatch}></ColorSwatch>
                            ) : (
                                <AttributeText fsize='13' fweight='400'>
                                    {x.product_attribute_value_value}
                                </AttributeText>
                            ),
                        )}
                </AttributesContainer>
            )}
        </DataRow>
    );
};

export default Rowitem;

const PriceContainer = styled.div`
    display: grid;
    grid-template-rows: auto;
    place-items: center flex-start;
    grid-row-gap: 10px;
`;

const StockContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    place-items: center flex-start;
`;

const AttributesContainer = styled.div`
    height: 50px;
    display: grid;
    grid-template-columns: 80px repeat(auto-fill, minmax(0px, auto));
    place-items: center flex-start;
    grid-column-gap: 20px;
`;
const AttributeText = styled(Text)`
    text-indent: 0px;
    font-size: 14px;
    font-weight: 700;
    :first-of-type {
        grid-column-start: 2;
    }
`;

const DataContainer = styled.div`
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 5fr 1fr 2fr;
    place-items: center flex-start;
    grid-column-gap: 20px;
`;
const AddBTN = styled(AddIcon)`
    cursor: pointer;
    place-self: center flex-end;
    margin-right: 20px;
    path {
        fill: ${(props) => props.theme.colors.blue1};
        :hover {
            fill: ${(props) => props.theme.colors.yellow};
        }
    }
`;

const WarehouseIcon = styled(WarehouseIco)`
    width: 24px;
    height: 24px;

    path {
        fill: ${(props) => (!!props.stock ? props.theme.colors.green : props.theme.colors.red)};
    }
`;

const FactoryIcon = styled(FactoryIco)`
    width: 24px;
    height: 24px;

    path {
        fill: ${(props) => (!!props.stock ? props.theme.colors.green : props.theme.colors.red)};
    }
`;

const ConfigurableIcon = styled(ConfigurableIco)`
    cursor: pointer;
    width: 24px;
    height: 24px;

    :hover {
        path {
            fill: ${(props) => props.theme.colors.orange};
        }
        path:nth-child(1) {
            fill: ${(props) => props.theme.colors.orange};
        }
    }
`;

const SingleProductIcoN = styled(SingleProductIco)`
    cursor: pointer;
    width: 24px;
    height: 24px;

    :hover {
        path {
            fill: ${(props) => props.theme.colors.orange};
        }
    }
`;
