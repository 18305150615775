import React, { useState, createContext, useEffect } from 'react';

export const LanguagesContext = createContext();

export const LanguagesProvider = (props) => {
    const [loading, setLoading] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [edittype, setEdittype] = useState();

    return (
        <LanguagesContext.Provider
            value={{
                loading,
                setLoading,
                languages,
                setLanguages,
                selectList,
                setSelectList,
                edit,
                setEdit,
                edittype,
                setEdittype,
            }}
        >
            {props.children}
        </LanguagesContext.Provider>
    );
};
