import React, { useState } from 'react';
import styled from 'styled-components';
const NavSubItem = (props) => {
    return <LinkItem active={props.selected2}>{props.children}</LinkItem>;
};

export default NavSubItem;

const LinkItem = styled.div`
    border-left: 10px solid #011b28;
    background-color: #011b28;
    font-size: 14px;
    text-indent: 30px;
    padding: 10px 0px;
    border-left: ${(props) => (props.active ? `5px solid ${props.theme.colors.orange}` : `5px solid #011b28`)};
    /* :hover {
        border-left: 10px solid ${(props) => `${props.theme.colors.orange}`};
    } */
`;
