import React, { useEffect, useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ToastContainer } from 'react-toastify';
import Nav from '../components/Nav/Nav';
import { auth, refreshtoken } from '../services/firebase';
import Axios from 'axios';
import { AppContext } from '../context/context';
import OverlayEdit from '../components/basic/OverlayEdit';

export const Privateroute = ({ component: Component, ...rest }) => {
    const { LOADING } = useContext(AppContext);
    const [showloading, setShowloading] = LOADING;
    let history = useHistory();

    auth.onAuthStateChanged(function (user) {
        if (user) {
            localStorage.setItem('MBS_TOKEN', user.za);
        } else {
            localStorage.removeItem('MBS_TOKEN');
            history.push('/login');
        }
    });

    Axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            console.log(error);

            const status = error.response ? error.response.status : null;

            if (status === 401 || !status) {
                return refreshtoken()
                    .then((token) => {
                        error.config.headers['Authorization'] = `Bearer ${token}`;
                        return Axios.request(error.config);
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            } else {
                return Promise.reject(error);
            }
        },
    );

    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem('MBS_TOKEN') ? (
                    <MainWrapper>
                        <Nav />

                        <MainContent>
                            <Component {...props} />
                            <ToastContainer
                                position='bottom-right'
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </MainContent>
                    </MainWrapper>
                ) : (
                    <Redirect to='/login' />
                )
            }
        />
    );
};
const MainWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: 1fr;
    height: 100vh;
`;
const MainContent = styled.div`
    padding: 0px 20px;
    grid-column-start: 2;
    position: relative;
    overflow-y: scroll;
`;
