import styled from 'styled-components';

const Button = styled.button`
    padding: 15px;
    border: 0;
    border-radius: 6px;
    outline: 0;
    margin: 8px 0px;
    background-color: ${(props) => props.color === 'primary' && props.theme.colors.green};
    border: ${(props) => props.color === 'primary' && `1px solid ${props.theme.colors.greenDark}`};
    background-color: ${(props) => props.color === 'secondary' && props.theme.colors.red};
    border: ${(props) => props.color === 'secondary' && `1px solid ${props.theme.colors.redDark}`};
    background-color: ${(props) => props.color === 'edit' && props.theme.colors.orange};
    border: ${(props) => props.color === 'edit' && `1px solid ${props.theme.colors.orangeDark}`};
    color: ${(props) => props.color === 'primary' && '#fff'};
    color: ${(props) => props.color === 'secondary' && '#fff'};
    color: ${(props) => props.color === 'edit' && '#fff'};
    transition: background-color 0.1s linear;
    font-size: 14px;
    font-family: 'Lato', Sans-Serif;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    place-self: ${(props) => props.psr && 'center flex-end'};
    place-self: ${(props) => props.psl && 'center flex-start'};
    place-self: ${(props) => props.psc && 'center center'};
    :hover {
        background-color: ${(props) => props.color === 'primary' && props.theme.colors.greenDark};
        border: ${(props) => props.color === 'primary' && `1px solid ${props.theme.colors.greenDark}`};
        background-color: ${(props) => props.color === 'secondary' && props.theme.colors.redDark};
        border: ${(props) => props.color === 'secondary' && `1px solid ${props.theme.colors.redDark}`};
        background-color: ${(props) => props.color === 'edit' && props.theme.colors.orangeDark};
        border: ${(props) => props.color === 'edit' && `1px solid ${props.theme.colors.orangeDark}`};
    }
    /* :focus {
        background-color: ${(props) => props.color === 'primary' && props.theme.colors.color3};
        border: ${(props) => props.color === 'primary' && `1px solid ${props.theme.colors.color2}`};
        background-color: ${(props) => props.color === 'secondary' && props.theme.colors.color2};
    } */
    /* :active {
        background-color: ${(props) => props.color === 'primary' && props.theme.colors.color3};
        border: ${(props) => props.color === 'primary' && `1px solid ${props.theme.colors.color2}`};
        background-color: ${(props) => props.color === 'secondary' && props.theme.colors.color2};
    } */
`;

export default Button;
