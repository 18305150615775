import React from 'react';
import styled from 'styled-components';
const Table = (props) => {
    return <TableWrapper {...props}>{props.children}</TableWrapper>;
};

export default Table;

const TableWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;
`;
