import styled from 'styled-components';

export const Styledtext = styled.p`
    margin: 0;
    font-family: ${(props) => (props.ffamily ? props.ffamily : "'Lato', sans-serif")};
    font-size: ${(props) => (props.fsize ? `${props.fsize}px` : '14px')};
    font-weight: ${(props) => (props.fweight ? props.fweight : 400)};
    line-height: ${(props) => (props.height ? props.height : '100%')};
    text-align: ${(props) => props.align && props.align};
    margin-top: ${(props) => props.mtop && `${props.mtop}px`};
    margin-bottom: ${(props) => props.mbot && `${props.mbot}px`};
    margin-left: ${(props) => props.mleft && `${props.mleft}px`};

    ${(props) =>
        props.blue1 &&
        `
        color: ${props.theme.colors.blue1};
        `}
    ${(props) =>
        props.color6 &&
        `
        color: ${props.theme.colors.color6};
        `}

    ${(props) =>
        props.f_xs &&
        `
            @media only screen and (max-width: 600px) {
                font-size: ${props.f_xs}px;
            }
        `}
`;

const Text = (props) => {
    return <Styledtext {...props}>{props.children}</Styledtext>;
};

export default Text;
