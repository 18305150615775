export const theme = {
    colors: {
        color1: `#222222`,
        color2: `#222222`,
        color3: '#222222',
        color4: '#023047', // main
        color5: '#222222',
        color6: 'red',
        blue1: '#023047',
        blue1Dark: '#011b28',
        blue2: '#219EBC',
        blue3: '#8ECAE6',
        yellow: '#FFB703',
        yellowDark: '#E0A100',
        red: '#EF476F',
        redDark: '#ED315D',
        green: '#06D6A0',
        greenDark: '#05B384',
        orange: '#FB8500',
        orangeDark: '#E07800',
    },
};
// color1: `#17252A`,
// color2: `#2B7A78`,
// color3: '#3AAFA9',
// color4: '#DEF2F1',
// color5: '#FEFFFF',
// color6: 'red',
