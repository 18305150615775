import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { AppProvider } from './context/context';
import { LanguagesProvider } from './context/LanguagesContext';
import { PagesProvider } from './context/PagesContext';
import { theme } from './components/theme';
import GlobalStyle from './components/globalStyles';
import { MemoryRouter } from 'react-router-dom';
import { PagesContext } from './context/PagesContext';

require('typeface-lato');

ReactDOM.render(
    <React.StrictMode>
        <MemoryRouter>
            <ThemeProvider theme={theme}>
                <AppProvider>
                    <LanguagesProvider>
                        <PagesProvider>
                            <App />
                        </PagesProvider>
                    </LanguagesProvider>
                </AppProvider>
                <GlobalStyle />
            </ThemeProvider>
        </MemoryRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
