import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Button from '../components/basic/Button';
import { AppContext } from '../context/context';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Rowitem from '../components/products/RowItem';
import Text from '../components/basic/text';
import Loading from '../components/basic/Loading';
import Table from '../components/Table/Table';
import ColumnRow from '../components/Table/ColumnRow';

const Products = () => {
    const { ALLPAGES, SHOWDIVANIMATION, PAGEDATA, PAGEDELETELIST, LOADING } = useContext(AppContext);
    const [allpages, setAllpages] = ALLPAGES;
    const [showdivanimation, setShowdivanimation] = SHOWDIVANIMATION;
    const [pagedata, setPagedata] = PAGEDATA;
    const [pagedeletelist, setPagedeletelist] = PAGEDELETELIST;
    const [showloading, setShowloading] = LOADING;

    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        Axios.get('https://mb-6ifbzgvb.ew.gateway.dev/products?limit=50', {
            crossdomain: true,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
            },
        })
            .then((res) => {
                setProducts(res.data.products);
            })
            .catch((error) => {
                error.response.status === 400 && toast.warning(error.response.data);
                error.response.status === 500 && toast.error(error.response.data);
            });
    };
    useEffect(() => {
        getProducts();
    }, []);
    return products.length > 0 ? (
        <Wrapper>
            <ButtonsWrapper>
                <Button color='secondary' onClick={async () => {}}>{`Delete ()`}</Button>
                <Button color='primary' onClick={() => {}}>
                    New page
                </Button>
            </ButtonsWrapper>
            <Table>
                <ColumnRow columns='1fr 1fr 1fr 1fr 2fr 5fr 1fr 2fr'>
                    <div></div>
                    <Text fsize='14' fweight='700'>
                        Type
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Active
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Image
                    </Text>

                    <Text fsize='14' fweight='700'>
                        SKU
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Name
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Price
                    </Text>
                    <Text fsize='14' fweight='700'>
                        Stock
                    </Text>
                </ColumnRow>

                {products.map((item, i) => (
                    <Rowitem columns='1fr 1fr 1fr 1fr 2fr 5fr 1fr 2fr' data={item} key={i} />
                ))}
            </Table>
        </Wrapper>
    ) : (
        <Loading />
    );
};
export default Products;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
`;

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-column-gap: 10px;
    place-content: center flex-end;
`;
