import React, { useState, createContext, useEffect } from 'react';
export const AppContext = createContext();

export const AppProvider = (props) => {
    const [pagedata, setPagedata] = useState();
    const [pagedeletelist, setPagedeletelist] = useState([]);
    const [allpages, setAllpages] = useState([]);
    const [showdivanimation, setShowdivanimation] = useState();
    const clean = () => {
        setPagedata();
        setPagedeletelist([]);
        setShowdivanimation();
    };

    const [showloading, setShowloading] = useState(false);

    return (
        <AppContext.Provider
            value={{
                CLEAN: clean,
                PAGEDATA: [pagedata, setPagedata],
                PAGEDELETELIST: [pagedeletelist, setPagedeletelist],
                ALLPAGES: [allpages, setAllpages],
                SHOWDIVANIMATION: [showdivanimation, setShowdivanimation],
                LOADING: [showloading, setShowloading],
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};
