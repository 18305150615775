import React from 'react';
import styled, { keyframes, css } from 'styled-components';
const OverlayEdit = (props) => {
    return <Wrapper {...props}>{props.children}</Wrapper>;
};

export default OverlayEdit;

const open = keyframes`
  0% {
    transform: translateX(1500px);

  }


  100% {
    transform: translateX(0px);

}
`;
const close = keyframes`
  0% {
    transform: translateX(0px);

  }


  100% {
    transform: translateX(1500px);



  }
`;

const Wrapper = styled.div`
    position: absolute;

    width: auto;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: rgba(250, 250, 250, 0.98);
    border-left: 1px solid #ccc;
    padding: 30px;
    z-index: 999;
    right: 0;
    animation: ${(props) =>
        props.animplay === true &&
        css`
            ${open} 0.1s ease-in;
        `};

    animation: ${(props) =>
        props.animplay === false &&
        css`
            ${close} 0.1s ease-out
        `};
`;
