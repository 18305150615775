import React, { useState, useEffect } from 'react';
import { auth, persistence } from '../services/firebase';
import styled from 'styled-components';
import Button from '../components/basic/Button';
import Input from '../components/basic/Input';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Text from '../components/basic/text';

//SVG
import { ReactComponent as CloseIcon } from '../icons/close.svg';

const Login = () => {
    let history = useHistory();

    const [acc, setAcc] = useState({
        companyEdit: localStorage.getItem('MBS_COMPANY') || '',
        company: localStorage.getItem('MBS_COMPANY') || '',
        user: '',
        pass: '',
    });

    return (
        <BG>
            <LoginWrapper>
                <form style={{ display: 'inherit' }} onSubmit={(e) => e.preventDefault()}>
                    <Logo src='./mb_logo.png' />
                    {/* {!localStorage.getItem('MBS_COMPANY') ? ( */}
                    {acc.companyEdit === '' ? (
                        <Input placeholder='Yritys' onChange={(e) => setAcc({ ...acc, company: e.target.value })} />
                    ) : (
                        <CompanyWrapper>
                            <Text color1 mleft='10' fsize='18' fweight='700'>
                                {localStorage.getItem('MBS_COMPANY')}
                            </Text>
                            <CloseBTN
                                onClick={() => {
                                    setAcc({ ...acc, company: '', companyEdit: '' });
                                    localStorage.removeItem('MBS_COMPANY');
                                }}
                            />
                        </CompanyWrapper>
                    )}
                    <Input
                        type='text'
                        placeholder='Käyttäjätunnus'
                        onChange={(e) => setAcc({ ...acc, user: e.target.value })}
                    />
                    <Input
                        type='password'
                        placeholder='Salasana'
                        onChange={(e) => setAcc({ ...acc, pass: e.target.value })}
                    />
                    <Button
                        type='submit'
                        color='primary'
                        onClick={() => {
                            setAcc({ ...acc, companyEdit: acc.company });
                            localStorage.setItem('MBS_COMPANY', acc.company);

                            auth.tenantId = acc.company;
                            auth.setPersistence(persistence).then(() => {
                                auth.signInWithEmailAndPassword(acc.user, acc.pass)
                                    .then((user) => {
                                        localStorage.setItem('MBS_TOKEN', user.za);

                                        history.push('/');
                                    })
                                    .catch((error) => {
                                        toast.error(error.message);

                                        console.log(error);
                                    });
                            });
                        }}
                    >
                        Kirjaudu
                    </Button>
                </form>
            </LoginWrapper>
        </BG>
    );
};

export default Login;

const CloseBTN = styled(CloseIcon)`
    cursor: pointer;
`;

const CompanyWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    place-items: center left;
    grid-column-gap: 10px;
`;

const BG = styled.div`
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 100vh;
    place-items: center center;
    background-color: ${(props) => props.theme.colors.color3};
`;

const LoginWrapper = styled.div`
    background-color: white;
    padding: 50px;
    display: grid;
    grid-template-columns: minmax(0px, 400px);
    grid-template-rows: auto auto auto;
`;

const Logo = styled.img`
    justify-self: center;
    width: 75%;
    margin-bottom: 40px;
`;
