import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as SwitchRAW } from '../../icons/switch_full.svg';

const SwitchSmall = (props) => {
    // useEffect(() => {
    //     props.setswitch(props.active);
    // }, []);
    return <Switch_toggle onClick={() => {}} animplay={props.active} />;
};

export default SwitchSmall;

const open = keyframes`
  0% {
    transform: translateX(-16px);
  }


  100% {
    transform: translateX(0px);
}
`;
const close = keyframes`
  0% {
    transform: translateX(0px);
  }


  100% {
    transform: translateX(-16px);


  }
`;

const Switch_toggle = styled(SwitchRAW)`
    margin-top: 4px;
    cursor: pointer;
    #switch {
        fill: ${(props) => (props.animplay ? props.theme.colors.green : props.theme.colors.red)};
        stroke: ${(props) => (props.animplay ? props.theme.colors.greenDark : props.theme.colors.redDark)};
        transform: ${(props) => (props.animplay ? 'translateX(0px)' : 'translateX(-16px)')};
        animation: ${(props) =>
            props.animplay === true &&
            css`
                ${open} 0.2s ease-in
            `};

        animation: ${(props) =>
            props.animplay === false &&
            css`
                ${close} 0.2s ease-out
            `};
    }
    #switch_bg {
        width: 32px;
        rx: 10;
        height: 16px;
    }
    #switch {
        width: 16px;
        height: 16px;
    }
`;
