import React, { useState, createContext, useEffect } from 'react';

export const PagesContext = createContext();

export const PagesProvider = (props) => {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    const [allpages, setAllpages] = useState([]);
    const [page, setPage] = useState({});
    const [selectList, setSelectList] = useState([]);
    return (
        <PagesContext.Provider
            value={{
                loading,
                setLoading,
                edit,
                setEdit,
                allpages,
                setAllpages,
                page,
                setPage,
                selectList,
                setSelectList,
            }}
        >
            {props.children}
        </PagesContext.Provider>
    );
};
