import styled from 'styled-components';
import React, { useEffect, useState, useContext } from 'react';
import { PagesContext } from '../../context/PagesContext';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as WarningIco } from '../../icons/warning.svg';
import Text from '../basic/text';

const Rowitem = (props) => {
    const {
        loading,
        setLoading,
        edit,
        setEdit,
        allpages,
        setAllpages,
        page,
        setPage,
        selectList,
        setSelectList,
    } = useContext(PagesContext);
    const [checked, setChecked] = useState(false);

    const GetPageById = async (id, from) => {
        try {
            const res = await Axios.get(`https://mb-6ifbzgvb.ew.gateway.dev/pages/${id}`, {
                crossdomain: true,
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
                },
            });

            if (res) {
                if (res.status === 200) {
                    setPage({ ...page, ...res.data[0], type: from });
                    setEdit(true);
                }
            }
        } catch (error) {
            error.response.status === 400 && toast.warning(error.response.data);
            error.response.status === 500 && toast.error(error.response.data);
        }
    };
    return (
        <RowContainer delete={selectList.includes(props.data.id) ? true : false}>
            <CheckBox
                type='checkbox'
                defaultChecked={selectList.includes(props.data.id) ? true : false}
                onClick={() => {
                    let dellanglist = [];
                    props.data.languages.map((x) => x.page_active && dellanglist.push(x.page_id));

                    if (!checked) {
                        setChecked(true);
                        setSelectList([...selectList, ...dellanglist]);
                    } else {
                        setChecked(false);
                        const filterlist = selectList.filter((x) => !dellanglist.includes(x) && x);
                        setSelectList(filterlist);
                    }
                }}
            />
            <NameWrapper>
                {!props.data.name && <WarningIcon />}
                <Text fsize='13' fweight='400'>
                    {!!props.data.name ? props.data.name : 'Page for default language is missing'}
                </Text>
            </NameWrapper>

            <LanguageContainer>
                {props.data.languages &&
                    props.data.languages.length > 0 &&
                    props.data.languages.map((x, i) =>
                        !!x.page_active || x.page_active === 0 ? (
                            <CountryFlag
                                key={i}
                                disabled={!x.page_active}
                                onClick={() => {
                                    setEdit(true);
                                    GetPageById(x.page_id, 'Edit page');
                                }}
                                src={'./flags/' + x.language_code.toLowerCase() + '.svg'}
                            />
                        ) : (
                            <div key={i} style={{ position: 'relative', display: 'grid' }}>
                                <CountryFlag
                                    disabled={!x.page_active}
                                    onClick={() => {
                                        GetPageById(x.page_id, 'Edit page');
                                    }}
                                    src={'./flags/' + x.language_code.toLowerCase() + '.svg'}
                                />
                                <RedCrossContainer>
                                    <RedCrossLine x1='0' y1='100%' x2='100%' y2='0'></RedCrossLine>
                                    <RedCrossLine x1='0' y1='0' x2='100%' y2='100%'></RedCrossLine>
                                </RedCrossContainer>
                            </div>
                        ),
                    )}
                {props.data.languages.find((x) => !!x.page_id && x.page_id) && (
                    <AddBTN
                        onClick={() => {
                            const pagevalue = props.data.languages.find((x) => !!x.page_id && x.page_id);

                            setEdit(true);
                            GetPageById(pagevalue.page_id, 'Add language');
                        }}
                    />
                )}
            </LanguageContainer>
        </RowContainer>
    );
};

export default Rowitem;

const RedCrossContainer = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const RedCrossLine = styled.line`
    stroke: ${(props) => props.theme.colors.red};
    stroke-width: 1;
`;

const RowContainer = styled.div`
    background: ${(props) =>
        props.delete
            ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(237,49,93,0.20297625886292014) 100%);'
            : '#fff'};
    user-select: none;
    height: 60px;
    display: grid;
    grid-template-columns: 80px 1fr 2fr;
    place-items: center flex-start;
    grid-template-rows: 1fr;

    :nth-child(odd) {
        background: ${(props) =>
            props.delete
                ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(237,49,93,0.20297625886292014) 100%)'
                : '#fafafa'};
    }
    /* :hover {
        background-color: ${(props) => props.theme.colors.blue3};
    } */
`;

const AddBTN = styled(AddIcon)`
    cursor: pointer;
    path {
        fill: ${(props) => props.theme.colors.blue1};
        :hover {
            fill: ${(props) => props.theme.colors.yellow};
        }
    }
`;

const WarningIcon = styled(WarningIco)`
    path {
        fill: ${(props) => props.theme.colors.yellow};
    }
`;

const CheckBox = styled.input`
    place-self: center;
    margin: 0;
    width: 18px;
    height: 18px;
`;

const LanguageContainer = styled.div`
    margin-left: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    place-items: center flex-start;
`;
const CountryFlag = styled.img`
    cursor: pointer;
    height: 16px;
    :hover {
        box-shadow: ${(props) => `0px 0px 0px 2px ${props.theme.colors.yellow}`};
    }
    :focus {
        box-shadow: ${(props) => `0px 0px 0px 2px ${props.theme.colors.yellow}`};
    }
    ${(props) => props.disabled && `opacity: 0.2;`}
`;

const NameWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    place-items: center;
`;
