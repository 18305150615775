import React from 'react';
import styled from 'styled-components';
const DataRow = (props) => {
    console.log(props.delete);
    return <RowContainer {...props}>{props.children}</RowContainer>;
};

export default DataRow;

const RowContainer = styled.div`
    background: ${(props) =>
        props.delete
            ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(237,49,93,0.20297625886292014) 100%);'
            : '#fff'};
    user-select: none;
    /* height: 80px; */
    display: grid;
    /* grid-template-columns: 80px 80px 80px 150px 150px 400px 100px 100px; */
    /* place-items: center flex-start; */
    /* grid-template-rows: 1fr; */
    grid-template-rows: 1fr;

    :nth-child(odd) {
        background: ${(props) =>
            props.delete
                ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(237,49,93,0.20297625886292014) 100%)'
                : '#fafafa'};
    }
    /* :hover {
        background-color: ${(props) => props.theme.colors.blue3};
    } */
`;
