import styled from 'styled-components';
import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as OKIcon } from '../../icons/ok.svg';
import Text from '../basic/text';
import DataRow from '../Table/DataRow';
import Checkbox from '../basic/Checkbox';
import CountryFlag from '../basic/CountryFlag';
import { LanguagesContext } from '../../context/LanguagesContext';
const Rowitem = (props) => {
    const {
        getLanguages,
        languages,
        setLanguages,
        edit,
        setEdit,
        selectList,
        setSelectList,
        edittype,
        setEdittype,
    } = useContext(LanguagesContext);
    const [checked, setChecked] = useState(false);

    return (
        <DataRow delete={selectList.includes(props.data.company_language_id) ? true : false}>
            <DataContainer>
                <Checkbox
                    type='checkbox'
                    defaultChecked={selectList.includes(props.data.company_language_id) ? true : false}
                    onClick={() => {
                        setSelectList([...selectList, props.data.company_language_id]);
                        let tmplist = selectList;

                        if (!checked) {
                            setChecked(true);
                            setSelectList([...selectList, props.data.company_language_id]);
                        } else {
                            setChecked(false);
                            const filterlist = tmplist.filter((x) => x !== props.data.company_language_id && x);
                            setSelectList(filterlist);
                        }
                    }}
                />
                <CountryFlag src={'./flags/' + props.data.language_code.toLowerCase() + '.svg'} />
                <Text fsize='13' fweight='400'>
                    {props.data.language_name}
                </Text>

                {props.data.company_language_default_language ? <OKSVG /> : <div></div>}
            </DataContainer>
        </DataRow>
    );
};

export default Rowitem;

const DataContainer = styled.div`
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 6fr 2fr;
    place-items: center flex-start;
    grid-column-gap: 20px;
`;

const OKSVG = styled(OKIcon)`
    path {
        fill: ${(props) => props.theme.colors.orange};
    }
`;
