import react, { useContext } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

import { LanguagesContext } from '../context/LanguagesContext';

const useLanguages = () => {
    const { setLanguages } = useContext(LanguagesContext);
    const getLanguages = async () => {
        Axios.get('https://mb-6ifbzgvb.ew.gateway.dev/company/languages', {
            crossdomain: true,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
            },
        })
            .then((res) => {
                setLanguages(res.data);
            })
            .catch((error) => {
                error.response.status === 400 && toast.warning(error.response.data);
                error.response.status === 500 && toast.error(error.response.data);
            });
    };
    return {
        getLanguages,
    };
};

export default useLanguages;
