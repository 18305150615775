import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow_down.svg';
import React, { useEffect, useState, useContext, useRef } from 'react';
import Axios from 'axios';
import Text from '../basic/text';

const LanguageSelector = (props) => {
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowdropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const GetLanguages = async () => {
        Axios.get(`https://mb-6ifbzgvb.ew.gateway.dev/languages`, {
            crossdomain: true,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
            },
        }).then((res) => {
            setLanguages(res.data);
        });
    };

    const GetCompanyLanguages = async () => {
        Axios.get(`https://mb-6ifbzgvb.ew.gateway.dev/company/languages`, {
            crossdomain: true,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('MBS_TOKEN')}`,
            },
        }).then((res) => {
            setLanguages(res.data);
        });
    };
    useEffect(() => {
        if (props.all) {
            GetLanguages();
        }
        if (props.company) {
            GetCompanyLanguages();
        }
    }, []);

    const [showdropdown, setShowdropdown] = useState(false);
    const [languages, setLanguages] = useState([]);
    let selectedlang;

    if (props.all) {
        selectedlang = languages.filter((x) => x.id === props.selected);
    }
    if (props.company) {
        selectedlang = languages.filter((x) => x.company_language_id === props.selected);
    }

    return (
        <Wrapper
            ref={ref}
            onClick={() => {
                !showdropdown ? setShowdropdown(true) : setShowdropdown(false);
            }}
        >
            {props.all && selectedlang.length > 0 ? (
                <img style={{ height: '24px' }} src={`./flags/${selectedlang[0].code.toLowerCase()}.svg`} />
            ) : props.company && selectedlang.length > 0 ? (
                <img style={{ height: '24px' }} src={`./flags/${selectedlang[0].language_code.toLowerCase()}.svg`} />
            ) : (
                <div>Select language</div>
            )}

            <ArrowDownBTN />
            {props.all && showdropdown && languages.length > 0 && (
                <Dropdown>
                    {languages.map((x) => (
                        <DropdownRow
                            onClick={() => {
                                props.setlang(x.id);
                            }}
                        >
                            <img style={{ height: '24px' }} src={`./flags/${x.code.toLowerCase()}.svg`} />
                            <Text blue1 fsize='16' fweight='400'>
                                {x.name}
                            </Text>
                        </DropdownRow>
                    ))}
                </Dropdown>
            )}
            {props.company && showdropdown && languages.length > 0 && (
                <Dropdown>
                    {languages.map((x) => (
                        <DropdownRow
                            onClick={() => {
                                props.setlang(x.company_language_id);
                            }}
                        >
                            <img style={{ height: '24px' }} src={`./flags/${x.language_code.toLowerCase()}.svg`} />
                            <Text blue1 fsize='16' fweight='400'>
                                {x.language_name}
                            </Text>
                        </DropdownRow>
                    ))}
                </Dropdown>
            )}
        </Wrapper>
    );
};

export default LanguageSelector;

const Wrapper = styled.div`
    display: Grid;
    position: relative;
    border: 1px solid #ccc;
    padding: 10px;
    grid-template-columns: minmax(40px, auto) 20px;
    grid-template-rows: 30px;
    grid-column-gap: 10px;
    place-items: center flex-start;
    user-select: none;
    background-color: #fff;
`;

const Dropdown = styled.div`
    background-color: #fff;
    display: Grid;
    position: absolute;
    top: 50px;
    left: 0px;
    box-shadow: 0px 0px 0px 1px #ccc;
    padding: 10px;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-template-rows: 30px;
    grid-row-gap: 10px;
    place-items: center flex-start;
    z-index: 200;
    max-height: 250px;
    overflow-y: scroll;
`;

const DropdownRow = styled.div`
    display: Grid;
    grid-template-columns: 40px 200px;
    grid-column-gap: 30px;
    place-items: center flex-start;
`;

const ArrowDownBTN = styled(ArrowDownIcon)`
    path {
        fill: ${(props) => props.theme.colors.blue1};
    }
`;
