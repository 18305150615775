import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as SwitchRAW } from '../../icons/switch_full.svg';

const Switch = (props) => {
    useEffect(() => {
        !!props.setswitch && props.setswitch(props.active);
    }, []);
    return (
        <Switch_toggle
            onClick={() => {
                !props.active ? props.setswitch(true) : props.setswitch(false);
            }}
            animplay={props.active}
        />
    );
};

export default Switch;

const open = keyframes`
  0% {
    transform: translateX(-24px);
  }


  100% {
    transform: translateX(0px);
}
`;
const close = keyframes`
  0% {
    transform: translateX(0px);
  }


  100% {
    transform: translateX(-24px);


  }
`;

const Switch_toggle = styled(SwitchRAW)`
    cursor: pointer;

    #switch_bg {
        background-color: #fff;
    }
    #switch {
        fill: ${(props) => (props.animplay ? props.theme.colors.green : props.theme.colors.red)};
        stroke: ${(props) => (props.animplay ? props.theme.colors.greenDark : props.theme.colors.redDark)};
        transform: ${(props) => (props.animplay ? 'translateX(0px)' : 'translateX(-24px)')};
        animation: ${(props) =>
            props.animplay === true &&
            css`
                ${open} 0.2s ease-in
            `};

        animation: ${(props) =>
            props.animplay === false &&
            css`
                ${close} 0.2s ease-out
            `};
    }
`;
