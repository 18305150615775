import React from 'react';
import styled from 'styled-components';
const Reports = () => {
    return (
        <IFRAME
            src='https://datastudio.google.com/embed/reporting/36cc8490-29cd-486a-8c0b-09ad74e0181e/page/pxGGC?source=testi'
            frameborder='0'
            style={{ border: 0 }}
            allowfullscreen
        ></IFRAME>
    );
};

export default Reports;

const IFRAME = styled.iframe`
    width: 100%;
    height: 100%;
`;
